<template>
  <v-container id="typography" fluid tag="section">
    <v-dialog v-model="dialogUpdate" max-width="500px">
      <v-card>
        <v-card-title>Are you sure you want to update status?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" depressed @click="closeUpdate">Cancel</v-btn>
          <v-btn color="primary darken-1" depressed @click="updateItemConfirm"
            >Update</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row align="center" justify="center">
      <v-col cols="12" v-if="ticket_details != null">
        <base-material-card color="#363636">
          <template v-slot:heading>
            <div class="text-h5 font-weight-light">
              <span
                >Ticket No. #{{ ticket_details.id }} | Order No. #{{
                  ticket_details.order_id
                }}</span
              >
            </div>
            <div class="text-h4 font-weight-light custom-align">
              <div>
                <span v-if="customer_details != null"
                  >Created by {{ customer_details.first_name }}
                  {{ customer_details.last_name }}</span
                >
                |
                <span> Create Date : {{ format_date(ticket_details.created_at) }}</span>
                |
                <span class="text-uppercase font-weight-bold"
                  >Status : {{ ticket_details.ticket_status }}</span
                >
              </div>
              <div>
                <span>
                  <v-select
                    class="nopadding"
                    :items="status"
                    v-model="ticket_details.ticket_status"
                    @change="updateStatus($event)"
                  ></v-select>
                </span>
              </div>
            </div>
          </template>
          <v-card-text>
            <div class="text-h4">
              <v-row>
                <v-col>
                  <v-card class="" elevation="" max-width="">
                    <v-card-text>
                      <p class="">
                        <span class="text-uppercase font-weight-bold">Subject</span>
                      </p>
                      <div class="text--primary">
                        <p>
                          {{ ticket_details.subject_line }}
                        </p>
                      </div>
                      <p class="">
                        <span class="text-uppercase font-weight-bold">Description</span>
                      </p>
                      <div class="text--primary">
                        <p>
                          {{ ticket_details.description }}
                        </p>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card class="" elevation="" max-width="">
                    <v-card-text>
                      <p class="">
                        <span class="text-uppercase font-weight-bold">Attachments</span>
                      </p>
                      <div class="text--primary d-flex row attachments-padding">
                        <li v-for="(image, index) in ticket_details.images">
                          <!-- <v-avatar>
                            <v-img
                              aspect-ratio="1"
                              :src="imageURL + image"
                              :lazy-src="imageURL + image"
                              @click="openNewTab(imageURL + image)"
                              style="cursor: pointer"
                            />
                          </v-avatar> -->
                          <a :href="imageURL + image" target="_blank">
                            {{ image }}
                          </a>
                          &nbsp;
                        </li>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-card class="" elevation="" max-width="">
                <v-card-text>
                  <p class="text-uppercase font-weight-bold">Comments</p>
                  <div class="wrapper custom-scrollbar" id="msg-container">
                    <v-card
                      :class="getMessageDirection(msg.sender_user_id)"
                      v-for="msg of messages"
                      :key="msg.time"
                    >
                      <div class="content">
                        <div class="avatar">
                          <img
                            :src="avatarURL + customer_details.customer_photo"
                            v-if="msg.sender_user_id == customer_details._id"
                          />
                          <img
                            src="../../../../../public/icon.png"
                            v-if="msg.sender_user_id != customer_details._id"
                          />
                        </div>

                        <div class="content-comment">
                          <div class="user">
                            <h5 v-if="msg.sender_user_id == customer_details._id">
                              {{
                                msg.user_details.first_name +
                                " " +
                                msg.user_details.last_name
                              }}
                            </h5>
                            <h5 v-if="msg.sender_user_id != customer_details._id">You</h5>
                            <span class="is-mute">{{ format_date(msg.time) }}</span>
                          </div>

                          <p>
                            {{ msg.message }}
                          </p>
                          <div
                            class="text--primary d-flex row attachments-padding"
                            v-if="msg.attachments.length > 0"
                          >
                            <li v-for="(image, index) in msg.attachments">
                              <!-- <v-avatar>
                            <v-img
                              aspect-ratio="1"
                              :src="imageURL + image"
                              :lazy-src="imageURL + image"
                              @click="openNewTab(imageURL + image)"
                              style="cursor: pointer"
                            />
                          </v-avatar> -->
                              <a :href="imageURL + image" target="_blank">
                                {{ image }}
                              </a>
                              &nbsp;
                              <!-- &nbsp; -->
                              <!-- <span
                                  v-if="index != msg.attachments.length - 1"
                                  style="color: black"
                                  >|</span
                                > -->
                            </li>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </div>
                  <div>
                    <span
                      ><b>Selected Files To Send : ({{ fileNames.length }})</b></span
                    >
                    <span v-if="fileNames.length > 0">
                      {{ fileNames.join(",") }} &nbsp;<span
                        style="color: red; cursor: pointer"
                        v-if="fileNames.length > 0"
                        @click="clearSelection"
                      >
                        Clear Selection
                      </span>
                    </span>
                    <v-text-field
                      class="messagearea"
                      v-model="enteredMessage"
                      variant="filled"
                      type="text"
                      placeholder="Message goes here!!"
                      @click:clear="clearMessage"
                      @keydown.enter="sendMessage"
                    >
                      <template v-slot:prepend-inner>
                        <v-file-input
                          ref="fileupload"
                          accept="image/png, image/gif, image/jpeg,application/pdf,image/jpg"
                          style="transform: rotate(90deg)"
                          multiple
                          label="File input"
                          hide-input
                          @change="attachFiles"
                        ></v-file-input>
                      </template>
                      <template v-slot:append>
                        <v-icon icon="mdi-send" start @click="sendMessage"
                          >mdi-send</v-icon
                        >
                      </template>
                    </v-text-field>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { commonService } from "../../services/common.service";

export default {
  props: ["id", "action"],
  data: () => ({
    messages: [],
    enteredMessage: "",
    status: ["Open", "Inprocess", "Close/Resolved"],
    ticket_status: null,
    ticket_details: null,
    customer_details: null,
    adminInfo: null,
    imageURL: process.env.VUE_APP_Image_Link + "Tickets/",
    avatarURL: process.env.VUE_APP_Image_Link + "Users/",
    dialogUpdate: false,
    updateTicketId: "",
    updateTicketStatus: "",
    files: [],
    fileNames: [],
  }),
  methods: {
    clearSelection() {
      this.files = [];
      this.fileNames = [];
      this.$refs.fileupload.value = null;
    },
    openNewTab(url) {
      window.open(url, "_blank");
    },
    getTicketData(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "ticket/edit/" + id)
        .then((response) => {
          if (response.status == 200) {
            this.ticket_details = response.data.ticket;
            this.customer_details =
              response.data.ticket.user_name.length > 0
                ? response.data.ticket.user_name[0]
                : null;
            this.getTicketMessages();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submit() {
      let data = {
        id: this.id,
        ticket_status: this.ticket_status,
      };
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "ticket/update", data)
        .then((response) => {
          if (response.status == 200) {
            this.$router.push({
              name: "Tickets",
              params: { edit: true },
            });
          }
        })
        .catch((error) => {});
    },
    format_date(value) {
      if (value) {
        var res = new Date(value);
        return moment(res).format("DD/MM/YYYY h:m:s a");
      }
    },
    getMessageDirection(sender_user_id) {
      if (sender_user_id == this.customer_details._id) {
        return "comment-left";
      } else {
        return "comment-right";
      }
    },
    closeUpdate() {
      this.dialogUpdate = false;
      this.getTicketData(this.id);
    },
    updateStatus(status) {
      console.log(status);
      this.dialogUpdate = true;
      this.updateTicketId = this.id;
      this.updateTicketStatus = status;
    },
    updateItemConfirm() {
      let data = {
        id: this.updateTicketId,
        ticket_status: this.updateTicketStatus,
      };
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "ticket/update", data)
        .then((response) => {
          if (response.status == 200) {
            this.dialogUpdate = false;
            this.editFlash = true;
            this.getTicketData(this.id);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sendMessage() {
      if (
        (this.enteredMessage === "" ||
          this.enteredMessage == "" ||
          this.enteredMessage == null ||
          this.enteredMessage == undefined) &&
        this.fileNames.length == 0
      ) {
        return;
      }
      const formData = new FormData();
      formData.append("ticket_id", this.id.toString());
      formData.append("message", this.enteredMessage);

      this.files.forEach((fileEl) => {
        formData.append("attachments[]", fileEl, fileEl.name);
      });

      axios
        .post(process.env.VUE_APP_API_BASE_URL + "ticket/sendMessage", formData)
        .then((response) => {
          this.files = [];
          this.fileNames = [];

          this.clearMessage();
          this.getTicketMessages();
          this.$refs.fileupload.value = null;
        })
        .catch((error) => {});
    },
    clearMessage() {
      this.enteredMessage = "";
    },
    attachFiles(event) {
      let FileArray = event;
      if (FileArray.length > 5) {
        alert("Maximum 5 files accepted per messaage.");
      } else {
        FileArray.forEach((element) => {
          this.fileNames.push(element.name);
          this.files.push(element);
        });
      }
    },
    getAdminInfo() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "user/data")
        .then((response) => {
          if (response.status == 200) {
            this.adminInfo = response.data.user;
          }
        })
        .catch((error) => {});
    },
    getTicketMessages() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "ticket/messages/" + this.id)
        .then((response) => {
          if (response.status == 200) {
            this.messages = response.data.messages;
            console.log(this.adminInfo);
            setTimeout(() => {
              var element = document.getElementById("msg-container");
              element.scrollTop = element.scrollHeight;
            }, 1000);
          }
        })
        .catch((error) => {});
    },
  },
  mounted() {
    this.getAdminInfo();

    if (this.id != undefined) {
      this.getTicketData(this.id);
      this.editTicket = true;
    } else {
      this.editTicket = false;
    }
  },
  created() {
    this.subscription = commonService.getEvent().subscribe((eventName) => {
      if (eventName.text == "refreshMessages") {
        if (this.id != undefined) {
          this.getTicketData(this.id);
          this.editTicket = true;
        } else {
          this.editTicket = false;
        }
      }
    });
  },
  beforeDestroy() {
    this.subscription.unsubscribe();
  },

  computed: {
    icon() {
      return this.icons[this.iconIndex];
    },
  },
};
</script>

<style lang="scss">
.wrapper {
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
  padding: 8px;
  display: flex;
  flex-direction: column;
  .comment-right {
    margin: 0 0 15px auto;
    width: 50%;
    display: flex;
    gap: 20px;
    user-select: none;
    .content {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: flex-start;
      gap: 16px;
      flex: 1;
      .avatar {
        height: 48px;
        width: 48px;
        img {
          max-width: 100%;
          border-radius: 999px;
          object-fit: cover;
        }
      }
      .rate {
        gap: 8px;
        display: flex;
        align-items: center;
        .value {
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          text-align: center;
          &.green {
            color: #00ba34;
          }
          &.red {
            color: #e92c2c;
          }
        }
      }
      &-comment {
        flex: 1;
        display: block;
        .user {
          gap: 12px;
          margin-bottom: 6px;
          align-items: center;
          display: flex;
        }
      }
      &-footer {
        margin-top: 12px;
        gap: 12px;
        display: flex;
        align-items: center;
      }
    }
  }
  .comment-left {
    width: 50%;
    display: flex;
    gap: 20px;
    user-select: none;
    .content {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: flex-start;
      gap: 16px;
      flex: 1;
      .avatar {
        height: 48px;
        width: 48px;
        img {
          max-width: 100%;
          border-radius: 999px;
          object-fit: cover;
        }
      }
      .rate {
        gap: 8px;
        display: flex;
        align-items: center;
        .value {
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          text-align: center;
          &.green {
            color: #00ba34;
          }
          &.red {
            color: #e92c2c;
          }
        }
      }
      &-comment {
        flex: 1;
        display: block;
        .user {
          gap: 12px;
          margin-bottom: 6px;
          align-items: center;
          display: flex;
        }
      }
      &-footer {
        margin-top: 12px;
        gap: 12px;
        display: flex;
        align-items: center;
      }
    }
  }
  .subcomment {
    display: flex;
    align-items: flex-start;
    .icon {
      width: 48px;
      height: 48px;
      color: #969696;
      display: flex;
      margin-right: 16px;
      align-items: center;
      justify-content: center;
    }
    .other_comments {
      flex: 1;
      display: grid;
      gap: 20px;
    }
  }
}
h5 {
  color: #1c1c1c;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.is-mute {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #969696;
}
.messagearea {
  padding: 8px;
  border-radius: 8px;
  margin: 0 auto;
}
.messagearea .v-icon {
  margin: 0 auto;
  font-size: xx-large;
  margin-top: 10px;
  cursor: pointer;
}
.custom-align {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.v-avatar {
  border-radius: 0;
  border: 1px solid;
}
.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}
.attachments-padding {
  padding: 12px;
}
.v-card {
  width: 100%;
  padding: 10px;
}

#msg-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#msg-container::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#msg-container::-webkit-scrollbar-thumb {
  background-color: #cf9602;
}
</style>
